import jwtDecode from 'jwt-decode'
import { QueryClient } from 'react-query'

import Api from '~/services/Api'
import Auth, { ActionParamsAuth } from '~/services/Auth'
import localStorage from '~/services/Storage/LocalStorage'
import sessionStorage from '~/services/Storage/SessionStorage'

const setupAuth = (queryClient: QueryClient) => {
  Auth.setup<UserModel>({
    key: 'user',
    queryClient,
    actions: {
      async authorize(token) {
        const actualToken = this.getToken() || token

        if (actualToken) this.setToken(actualToken)

        if (!actualToken) return null

        const tokenData = this.getTokenData()

        Api.setAuthToken(actualToken)

        const Actioncable = (await import('~/services/acable')).default
        Actioncable.updateConsumer(actualToken)

        const data = await this.loadUser(tokenData?.role)

        return { ...tokenData, ...data, isLoaded: true }
      },
      setToken(token) {
        return localStorage.setItem('accessToken', token)
      },
      getToken() {
        return localStorage.getItem('accessToken')
      },
      getTokenData() {
        const token = this.getToken()

        try {
          const data = jwtDecode<any>(token || '') || {}
          return data
        } catch (err) {
          return {}
        }
      },
      setTokenData:
        (data) =>
        async ({ setUser }: ActionParamsAuth<UserModel>) => {
          setUser(data)
        },

      loadUser(role: string) {
        return async ({ roles }: ActionParamsAuth<UserModel>) => {
          let resp = {}

          if (role === roles.user) {
            resp = await Api.getUser()
          }

          if (role === roles.cake_maker) {
            resp = await Api.getCakeMaker()
          }

          return { ...resp }
        }
      },
      login(data: SignInDTO) {
        return async ({ setUser }: ActionParamsAuth<UserModel>) => {
          const resp = await Api.login(data)

          const dataUser = await this.authorize(resp.jwt_token)
          setUser(dataUser)

          return dataUser
        }
      },
      loginGoogle(jwt: string) {
        return async ({ setUser }: ActionParamsAuth<UserModel>) => {
          const dataUser = await this.authorize(jwt)
          setUser(dataUser)

          return dataUser
        }
      },

      register: (data: SignUpDTO) => Api.createUser(data),
      registerCakeMaker: (
        data: SignUpCakeMakerDTO,
        package_id: number,
        recaptcha: string
      ) => Api.createCakeMaker(data, package_id, recaptcha),
      logout:
        () =>
        async ({ clearUser }: ActionParamsAuth<UserModel>) => {
          const Actioncable = (await import('~/services/acable')).default

          clearUser()
          localStorage.clear()
          sessionStorage.clear()
          Actioncable.disconnectConsumer()
          Api.setAuthToken(null)
        }
    },
    roles: {
      user: 'user',
      cake_maker: 'cake_maker'
    },
    selectors: {
      isAuthorized: () => !!localStorage.getItem('accessToken'),
      isConfirmed: ({ user }) => !!user?.confirmed,
      isLogged: ({ user }) => !!user?.confirmed,
      isCakeMaker: ({ user, roles }) => user?.role === roles.cake_maker,
      getRole: ({ user }) => user?.role,
      hasRole({ user }, role) {
        return role === user?.role
      },
      isLoadedUser: ({ user }) => !!user?.isLoaded,
      getUserName: ({ user }) =>
        user?.full_name ||
        (user?.first_name && `${user?.first_name} ${user?.last_name}`)
    }
  })
}

export default setupAuth
