/* eslint-disable class-methods-use-this */
/* eslint-disable max-classes-per-file */
import { QueryClient, QueryKey } from 'react-query'
import { Updater } from 'react-query/types/core/utils'

export interface RolesAuth {}
export interface ActionParamsAuth<UserT = any> {
  roles: RolesAuth
  setUser: (user: any) => void
  getUser: () => UserT | undefined
  clearUser: () => void
}

export interface ActionsAuth {
  [key: string]: (...rest: any) => any
}

export interface ActionsConfigAuth {
  [key: string]: (...rest: any) => any
}

interface SelectorAuthParams<UserT> {
  user: UserT
  roles: RolesAuth
}

export interface SelectorsConfigAuth<UserT> {
  isLogged: ({ user, roles }: SelectorAuthParams<UserT>) => boolean
  isConfirmed: ({ user, roles }: SelectorAuthParams<UserT>) => boolean
  isAuthorized: ({ user, roles }: SelectorAuthParams<UserT>) => boolean
  getRole: ({ user, roles }: SelectorAuthParams<UserT>) => string
  [key: string]: (
    { user, roles }: SelectorAuthParams<UserT>,
    ...rest: any
  ) => any
}

export interface SelectorsAuth {
  isLogged: () => boolean
  isConfirmed: () => boolean
  isAuthorized: () => boolean
  getRole: () => string
  [key: string]: (...rest: any) => any
}

export interface AuthConfig<UserT = any> {
  key?: QueryKey
  queryClient: QueryClient
  actions: ActionsConfigAuth
  selectors: SelectorsConfigAuth<UserT>
  roles: RolesAuth
}

class AuthController<UserT> {
  public key: QueryKey = 'user' as QueryKey
  public selectors: SelectorsAuth
  public actions: ActionsAuth
  public roles: RolesAuth
  private queryClient: AuthConfig['queryClient']

  constructor(config: AuthConfig) {
    this.key = (config.key || 'user') as QueryKey
    this.roles = config.roles
    this.queryClient = config.queryClient

    this.actions = this.generateActions(config.actions) as ActionsAuth
    this.selectors = this.generateSelectors(config.selectors) as SelectorsAuth
  }

  /*
   * Methods
   */
  setUser = <T = any>(updater: Updater<T | undefined, T>) => {
    this.queryClient?.setQueryData(this.key, updater)
  }

  getUser = <T = unknown>(): T | undefined => {
    return this.queryClient?.getQueryData(this.key)
  }

  clearUser = () => {
    this.setUser(undefined)
  }

  /*
   * Utils
   */
  private generateActions(actions: ActionsConfigAuth) {
    return Object.entries(actions || {}).reduce((acc, [key, valueFn]) => {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const that = this
      // eslint-disable-next-line no-param-reassign
      acc = {
        ...acc,
        [key](...args) {
          const action = valueFn.call(this, ...args)

          if (typeof action === 'function') {
            return action.call(this, {
              roles: that.roles,
              setUser: that.setUser,
              getUser: that.getUser,
              clearUser: that.clearUser
            })
          }

          return action
        }
      }

      return acc
    }, {})
  }

  private generateSelectors(selectors: SelectorsConfigAuth<UserT | undefined>) {
    return Object.entries(selectors || {}).reduce((acc, [key, valueFn]) => {
      acc[key] = (...args) => {
        const user = this.getUser<UserT>()
        return valueFn({ user, roles: this.roles }, ...args)
      }

      return acc
    }, {})
  }
}

/*
 * Auth factory
 */
class Auth {
  controller: AuthController<any> = {} as AuthController<any>

  setup = <UserT>(config: AuthConfig<UserT>) => {
    this.controller = new AuthController<UserT>(config)
  }
}

export default new Auth()
