import Axios from '../../Axios'
import { AnyConstructor, Mixin } from '../types'

type AxiosType = typeof Axios

function SettingsMixin<TBase extends AnyConstructor>(Base: TBase & AxiosType) {
  return class Settings extends Base {
    /**
     * Update password
     */
    updatePassword = (passwordData: UpdatePasswordDTO): Promise<string> =>
      this.put('/api/v1/change_password', {
        data: { change_password: passwordData },
        deserialize: true
      })
    /**
     * Get customer email preferences
     */
    getCustomerEmailPreferences = (): Promise<EmailPreferencesCutomerModel> =>
      this.get(`api/v1/users/setting`, { deserialize: true })
    /**
     * Update customer email preferences
     */
    updateCustomerEmailPreferences = (
      params: EmailPreferencesCutomerModel
    ): Promise<void> =>
      this.put(`api/v1/users/setting`, {
        data: {
          ...params
        }
      })
    /**
     * Get caker email preferences
     */
    getCakerEmailPreferences = (): Promise<EmailPreferencesCakerModel> =>
      this.get(`api/v1/cake_makers/setting`, { deserialize: true })
    /**
     * Update caker email preferences
     */
    updateCakerEmailPreferences = (
      params: EmailPreferencesCakerModel
    ): Promise<void> =>
      this.put(`api/v1/cake_makers/setting`, {
        data: {
          ...params
        }
      })
  }
}

export type SettingsMixinType = Mixin<typeof SettingsMixin>

export default SettingsMixin
