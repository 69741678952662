import Auth from '../Auth'

export const logoutInterceptor = (error) => {
  const { status } = error.response

  const unauthorized = [401]

  if (unauthorized.includes(status)) {
    Auth.controller.actions.logout()
  }

  return Promise.reject(error)
}
