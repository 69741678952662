import Axios from '../../Axios'
import { AnyConstructor, Mixin } from '../types'

type AxiosType = typeof Axios

function FavoritesMixin<TBase extends AnyConstructor>(Base: TBase & AxiosType) {
  return class Favorites extends Base {
    /**
     * Get all followers of current user
     */
    getFavorites = (params: FavoriteDTO): Promise<PaginationFavoriteModel> =>
      this.get('/api/v1/users/favorite_cake_makers', {
        params: { ...params }
      })
    /**
     * Follow to user
     */
    createFavorite = (cake_maker_id: number): Promise<string> =>
      this.post('/api/v1/users/favorite_cake_makers', {
        data: {
          cake_maker_id
        }
      })
    /**
     * Unfollow to user
     */
    deleteFavourite = (cake_maker_id: number): Promise<string> =>
      this.del(`/api/v1/users/favorite_cake_makers/${cake_maker_id}`)
  }
}

export type FavoritesMixinType = Mixin<typeof FavoritesMixin>

export default FavoritesMixin
