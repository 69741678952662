var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"sLnpKjDIWRvaLfY1wDL7j"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs'

import { IS_PRODUCTION } from '~/utils/constants/env.constants'

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN

if (IS_PRODUCTION) {
  Sentry.init({
    dsn: SENTRY_DSN,
    tracesSampleRate: 1.0
  })
}
