import dynamic from 'next/dynamic'

import useAuth from '~/services/Auth/useAuth'
import sessionStorage from '~/services/Storage/SessionStorage'

const Disclaimer = dynamic(() => import('~/views/Disclaimer'))

const DisclaimerContainer = () => {
  const { user, selectors, roles } = useAuth()
  const { stripe_account_connected: isStripeConnected, subscription } =
    user || {}
  const isCakeMaker = selectors.hasRole(roles.cake_maker)

  const isHideDisclaimer = sessionStorage.getItem('hideDisclaimer')
  const isShowDisclaimer =
    !isHideDisclaimer && isCakeMaker && (!isStripeConnected || !subscription)

  if (!user?.first_name || !isShowDisclaimer) return null

  return <Disclaimer />
}

export default DisclaimerContainer
