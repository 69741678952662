import Axios from '../../Axios'
import { AnyConstructor, Mixin } from '../types'

type AxiosType = typeof Axios

function OrdersMixin<TBase extends AnyConstructor>(Base: TBase & AxiosType) {
  return class Quote extends Base {
    getCakeMakerOrders = (params: OrdersDTO): Promise<OrdersModel> =>
      this.get('/api/v1/cake_makers/orders', { params, deserialize: true })

    getUserOrders = (params: OrdersDTO): Promise<OrdersModel> =>
      this.get('/api/v1/users/orders', { params, deserialize: true })

    userOrderEarlyPayment = (
      values: EarlyPaymentOrderDTO
    ): Promise<EarlyPaymentOrderModel> =>
      this.post('/api/v1/payments/early_last_payment', {
        data: values
      })

    getCakeMakerOrderById = (id: number): Promise<OrderModel> =>
      this.get(`/api/v1/cake_makers/orders/${id}`, { deserialize: true })

    getUserOrderById = (id: number): Promise<OrderModel> =>
      this.get(`/api/v1/users/orders/${id}`, { deserialize: true })

    cakeMakerCancelOrder = (values: DeclineDTO): Promise<void> =>
      this.post('/api/v1/cake_makers/decline', {
        data: values
      })

    userCancelOrder = (values: DeclineDTO): Promise<void> =>
      this.post('/api/v1/users/decline', {
        data: values
      })

    getCountUnViewedOrders = (): Promise<CountUnViewedOrdersModel> =>
      this.get(`/api/v1/cake_makers/orders/monitoring`, { deserialize: true })

    createPdfCaker = (id: number): Promise<string> =>
      this.get(`/api/v1/cake_makers/document`, { params: { id } })

    createPdfUser = (id: number): Promise<string> =>
      this.get(`/api/v1/users/document`, { params: { id } })

    manualEarlyPayment = (
      order_id: number
    ): Promise<{ stripe_payment_link: string }> =>
      this.post(`/api/v1/payments/manual_early_last_payment`, {
        data: { order_id },
        deserialize: true
      })
  }
}

export type OrdersMixinType = Mixin<typeof OrdersMixin>

export default OrdersMixin
