import { useContext } from 'react'

import AuthContext, { AuthContextType } from './Auth.context'

const useAuth = <UserT = any>() => {
  const context = useContext<AuthContextType<UserT>>(AuthContext)

  if (!context) {
    throw new Error(`useAuth must be used within an AuthProvider`)
  }

  return context
}

export default useAuth
