import Axios from '../../Axios'
import { AnyConstructor, Mixin } from '../types'

type AxiosType = typeof Axios

function ArticlesMixin<TBase extends AnyConstructor>(Base: TBase & AxiosType) {
  return class Articles extends Base {
    /**
     * Get Articles
     */
    getAllArticles = (params: ArticleDTO): Promise<PaginationArticleModel> =>
      this.get('/api/v1/articles', { params })
    /**
     * Get article by id
     */
    getArticle = (slug: string): Promise<ArticleModel> =>
      this.get(`/api/v1/articles/${slug}`)
    /**
     * Get all article slugs
     */
    getAllSlugMediaPage = (): Promise<{ slugs: string[] }> =>
      this.get(`/api/v1/sitemaps/media_slugs`)

    rateArcticle = (values: {
      mark: number
      article_id: string
    }): Promise<void> => this.post(`/api/v1/articles/ratings`, { data: values })
  }
}

export type ArticlesMixinType = Mixin<typeof ArticlesMixin>

export default ArticlesMixin
