import { IS_BROWSER } from '~/utils/constants/env.constants'

import BaseStorage from './BaseStorage'

type ItemName = 'hideDisclaimer'

class SessionStorage extends BaseStorage<ItemName> {
  constructor() {
    super()

    if (IS_BROWSER) {
      this.storage = window.sessionStorage
    }
  }
}

const sessionStorage = new SessionStorage()

export default sessionStorage
