export const GTM_ID = process.env.NEXT_PUBLIC_GTM_ID

declare global {
  interface Window {
    dataLayer: any
  }
}

export const pageview = (url: string) => {
  if (!window.dataLayer) return

  window.dataLayer.push({
    event: 'pageview',
    page: url
  })
}
