import React, { useMemo, useRef } from 'react'

import { useQuery, UseQueryOptions } from 'react-query'

import { IS_BROWSER } from '~/utils/constants/env.constants'

import Api from '../Api'

import AuthContext from './Auth.context'
import Auth from './Auth.controller'

export interface AuthProviderProps {
  queryOptions?: UseQueryOptions
}

const AuthProvider = <UserT,>({
  children,
  queryOptions
}: React.PropsWithChildren<AuthProviderProps>) => {
  const actions = useRef(Auth.controller.actions)
  const selectors = useRef(Auth.controller.selectors)
  const roles = useRef(Auth.controller.roles)

  const setInitialData = () => {
    Api.setAuthToken(actions.current.getToken())

    return {
      ...((IS_BROWSER && actions.current.getTokenData()) || {})
    }
  }

  const {
    data: user,
    error,
    isLoading,
    isIdle,
    refetch,
    isFetched
  } = useQuery({
    queryKey: Auth.controller.key,
    queryFn: () => actions.current.authorize(),
    refetchIntervalInBackground: false,
    retry: false,
    refetchInterval: false,
    initialData: setInitialData(),
    ...queryOptions
  })

  const value = useMemo(
    () => ({
      user: user as UserT,
      state: {
        error,
        isFetched,
        isLoading,
        isIdle,
        isConfirmed: selectors.current?.isConfirmed(),
        isLogged: selectors.current?.isLogged(),
        isAuthorized: selectors.current?.isAuthorized()
      },
      methods: {
        refetch,
        setUser: Auth.controller.setUser,
        clearUser: Auth.controller.clearUser,
        getUser: Auth.controller.getUser
      },
      actions: actions.current || {},
      selectors: selectors.current || {},
      roles: roles.current || {}
    }),
    [user, error, isFetched, isLoading, isIdle, refetch]
  )

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export default AuthProvider
