class BaseStorage<T extends string> {
  storage: Storage | null = null

  getItem(itemName: T) {
    if (!this.storage) {
      return null
    }

    const item = this.storage.getItem(itemName)
    const numPatt = new RegExp(/^\d+$/)
    const jsonPatt = new RegExp(/[[{].*[}\]]/)

    if (item) {
      if (jsonPatt.test(item)) {
        return JSON.parse(item)
      }
      if (numPatt.test(item)) {
        return parseFloat(item)
      }

      return item
    }

    return null
  }

  async setItem(itemName: T, item: unknown) {
    if (!this.storage) {
      return
    }

    if (typeof item === 'object') {
      this.storage.setItem(itemName, JSON.stringify(item))
    } else {
      this.storage.setItem(itemName, String(item))
    }
  }

  async removeItem(itemName: T) {
    if (!this.storage) {
      return
    }

    this.storage.removeItem(itemName)
  }

  async clear() {
    if (!this.storage) {
      return
    }

    this.storage.clear()
  }
}

export default BaseStorage
