import Axios from '../../Axios'
import { AnyConstructor, Mixin } from '../types'

type AxiosType = typeof Axios

function UserMixin<TBase extends AnyConstructor>(Base: TBase & AxiosType) {
  return class User extends Base {
    /**
     * User login
     */
    login = (user: SignInDTO): Promise<{ jwt_token: string }> =>
      this.post('/api/v1/authentication', {
        data: user,
        deserialize: true
      })
    /**
     * Create a new user
     */
    createUser = (user: SignUpDTO): Promise<UserModel> =>
      this.post('/api/v1/user', { data: { user } })
    /**
     * Get user data
     */
    getUser = (): Promise<UserModel> =>
      this.get(`/api/v1/user`, { deserialize: true })
    /**
     * Create a new cake maker
     */
    createCakeMaker = (
      cake_maker: SignUpCakeMakerDTO,
      package_id: number,
      recaptcha: string
    ): Promise<CakeMakerModel> =>
      this.post('/api/v1/cake_maker', {
        data: {
          cake_maker,
          package_id,
          request: {
            recaptcha
          }
        }
      })
    /**
     * Get cake maker
     */
    getCakeMaker = (): Promise<CakeMakerModel> =>
      this.get(`/api/v1/cake_maker`, { deserialize: true })
    /**
     * Get cake maker
     */
    getCakeMakers = (params?: SearchFiltersDTO): Promise<CakeMakerModel[]> =>
      this.get('/api/v1/cake_makers', {
        params,
        deserialize: true
      })
    /**
     * Update cake_maker
     */
    updateCakeMaker = (
      cake_maker: any,
      package_id: string
    ): Promise<CakeMakerModel> =>
      this.put(`/api/v1/cake_maker`, {
        data: { cake_maker, package_id },
        deserialize: true
      })
    /**
     * Confirm user link
     */
    confirmUser = (
      confirmation_token: string,
      account_type: string
    ): Promise<any> =>
      this.post(`/api/v1/email_confirmation`, {
        data: { confirmation_token, account_type }
      })
    /**
     * Forgot password
     */
    sendResetLink = (
      email: string,
      account_type: string,
      recaptcha: string
    ): Promise<void> =>
      this.post('/api/v1/forgot_password', {
        data: {
          email,
          account_type,
          request: {
            recaptcha
          }
        }
      })
    /**
     * Reset password
     */
    resetPassword = (values): Promise<any> =>
      this.put(`/api/v1/forgot_password`, { data: values })
    /**
     * Update user
     */
    updateUser = (data: Partial<Omit<UserModel, 'id'>>): Promise<UserModel> =>
      this.put(`/api/v1/user`, { data, deserialize: true })
    /**
     * Delete user
     */
    deleteUser = (): Promise<string> => this.del(`/api/v1/user`)
    /**
     * Delete cake maker
     */
    deleteCakeMaker = (): Promise<string> => this.del(`/api/v1/cake_maker`)
    /**
     * Get packages data
     */
    getPackakes = (): Promise<PackageModel[]> =>
      this.get(`/api/v1/packages`, { deserialize: true })
    /**
     * Get calendar data
     */
    getCalendar = (date: Date): Promise<CalendarModel> =>
      this.get(`/api/v1/cake_makers/calendar`, {
        params: { date },
        deserialize: true
      })
    /**
     * Set calendar day off
     */
    setDayOff = (values: ManageDayDTO): Promise<ManageCalendarModel[]> =>
      this.post(`/api/v1/cake_makers/calendar`, {
        data: values,
        deserialize: true
      })
    /**
     * Remove calendar day off
     */
    removeDayOff = (
      values: ManageDeleteDayDTO
    ): Promise<ManageCalendarModel[]> =>
      this.del(`/api/v1/cake_makers/calendar`, {
        data: values,
        deserialize: true
      })
    /**
     * Get type of bakes
     */
    getBakeCategories = (): Promise<SelectAttributes[]> =>
      this.get(`/api/v1/bake_categories`, { deserialize: true })
    /**
     * Get types of event
     */
    getEventCategories = (): Promise<SelectAttributes[]> =>
      this.get(`/api/v1/event_categories`, { deserialize: true })
    /**
     * Get dietary requirements
     */
    getDietaryRequirements = (): Promise<SelectAttributes[]> =>
      this.get(`/api/v1/dietary_requirements`, { deserialize: true })
    /**
     * Get delivery radius
     */
    getDeliveryRadius = (): Promise<DeliveryRadiusModel[]> =>
      this.get(`/api/v1/delivery_radius`, { deserialize: true })

    /**
     * Resend confirmation email
     */
    resendConfirmationEmail = (email: string): Promise<void> =>
      this.post('/api/v1/user/resend_confirmation', {
        data: { email }
      })
    /**
     * signin with google
     */
    authorizationWithGoogle = (data): Promise<GoogleFV> =>
      this.post('api/v1/oauth/google', {
        data
      })
    /**
     * Refresh token
     */
    refreshToken = (): Promise<any> => this.post('/api/v1/user/tokens')
    /**
     * Get calendar data by id
     */
    getUnavailableDate = (
      cake_maker_id: string,
      date: Date
    ): Promise<ManageCalendarModel[]> =>
      this.get(`/api/v1/cake_maker_calendars/${cake_maker_id}`, {
        params: { date },
        deserialize: true
      })
    /**
     * Get cake maler by id
     */
    getCakeMakerById = (cake_maker_id: string): Promise<CakeMakerModel> =>
      this.get(`/api/v1/cake_makers/${cake_maker_id}`, {
        deserialize: true
      })
    /**
     * Manage subscription
     */
    manageSubscription = (): Promise<string> =>
      this.post(`/api/v1/cake_makers/billings`, {
        deserialize: true
      })
    /**
     * Stripe connection
     */
    stipeConnection = (): Promise<string> =>
      this.post(`/api/v1/payments/connect/account_links`, {
        deserialize: true
      })
    /**
     * Stripe disconnect
     */
    stipeDisconnect = (): Promise<string> =>
      this.put(`/api/v1/cake_makers/stripe_account_disconnect`, {
        deserialize: true
      })
    /**
     * Get all cake_makers ids
     */
    getAllSlugCakeMakers = (): Promise<{ slugs: string[] }> =>
      this.get('/api/v1/sitemaps/cake_maker_slugs')
    /**
     * Calculate cake recipe
     */
    calculateCakeRecipe = (
      data: CalculateCakeRecipeDTO
    ): Promise<CalculatedModel> =>
      this.post('/api/v1/calculator', { data, deserialize: true })
  }
}

export type UserMixinType = Mixin<typeof UserMixin>

export default UserMixin
