import React, { useState } from 'react'

import { Theme, useMediaQuery } from '@mui/material'

import localStorage from '~/services/Storage/LocalStorage'

import DisclaimerContainer from './DisclaimerContainer'
import WelcomeInfoContainer from './WelcomeInfoContainer'

const NotificationContainer = () => {
  const [isOpen, setIsOpen] = useState(true)
  const isWelcomePopup = localStorage.getItem('showWelcomePopup')
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))

  return (
    <>
      {isMobile && isWelcomePopup && isOpen ? null : <DisclaimerContainer />}

      <WelcomeInfoContainer isOpen={isOpen} onClose={() => setIsOpen(false)} />
    </>
  )
}

export default NotificationContainer
