import Axios from '../../Axios'
import { AnyConstructor, Mixin } from '../types'

type AxiosType = typeof Axios

function DashboardMixin<TBase extends AnyConstructor>(Base: TBase & AxiosType) {
  return class Dashboard extends Base {
    getStatistic = (): Promise<DashboardStaticModel> =>
      this.get('/api/v1/cake_makers/statistic', { deserialize: true })

    getDynamicDate = (params: any): Promise<DiagramsModel> =>
      this.get('/api/v1/cake_makers/performance', { params, deserialize: true })
  }
}

export type DashboardMixinType = Mixin<typeof DashboardMixin>

export default DashboardMixin
