import Axios from '../../Axios'
import { AnyConstructor, Mixin } from '../types'

type AxiosType = typeof Axios

function ReviewMixin<TBase extends AnyConstructor>(Base: TBase & AxiosType) {
  return class Review extends Base {
    /**
     * Create a new review
     */
    createReview = (review: ReviewsDTO): Promise<ReviewModel> =>
      this.post('/api/v1/users/reviews', { data: { review } })
    /**
     * Get all reviews
     */
    getAllReviews = (
      params: ReviewsPaginationDTO
    ): Promise<ReviewsPaginationModel> =>
      this.get(`/api/v1/reviews`, { params })
    /**
     * Get all customer reviews
     */
    getAllCustomerReviews = (
      params: ReviewsPaginationDTO
    ): Promise<ReviewsPaginationModel> =>
      this.get(`/api/v1/users/reviews`, { params })
    /**
     * Get  customer un viewed count
     */
    getCustomerCountUnViewed = (): Promise<CountUnViewedReviewsModel> =>
      this.get(`/api/v1/users/reviews/monitoring`, { deserialize: true })
  }
}

export type ReviewMixinType = Mixin<typeof ReviewMixin>

export default ReviewMixin
