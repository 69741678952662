import type { PaletteOptions } from '@mui/material/styles'

const palette: PaletteOptions = {
  primary: {
    main: '#2C397C'
  },
  secondary: {
    main: '#F6BD5A',
    dark: '#EF8F36'
  },
  success: {
    main: '#60A18E',
    light: '#E4F4F0'
  },
  third: {
    main: '#C7305A',
    dark: '#E1636C',
    light: '#FADFDF'
  },
  // error: {
  //   main: '#FF6666'
  // },
  stroke: {
    main: '#D0D4E7',
    light: '#7A83B2',
    dark: '#A4A8BB'
  },
  text: {
    primary: '#131938',
    secondary: '#2B304F',
    headline: '#5F6380',
    stroke: '#A4A8BB'
  },
  background: {
    default: '#FFFFFF',
    grey: '#F6F5F1',
    light: '#FAF7EF',
    lightPink: '#F8C1D3',
    lightOrange: '#FFF0C8',
    lightBlue: '#E4E7F5',
    lightYellow: '#FFF8E7',
    yellowSecondary: '#FFF8E6',
    blueSecondary: '#F0F3FF',
    pinkSecondary: '#FFE4CC',
    blueWhite: '#F3F3FA',
    blueDark: '#0A185E'
  }
}

export default palette
