import Axios from '../../Axios'
import { AnyConstructor, Mixin } from '../types'

type AxiosType = typeof Axios

function QuoteMixin<TBase extends AnyConstructor>(Base: TBase & AxiosType) {
  return class Quote extends Base {
    createQuote = (values: RequestModel): Promise<RequestModel> =>
      this.post('/api/v1/users/requests', {
        data: { ...values }
      })

    getCakeMakerRequests = (params: RequestsDTO): Promise<RequestsModel> =>
      this.get('/api/v1/cake_makers/requests', { params, deserialize: true })

    getUserRequests = (params: RequestsDTO): Promise<RequestsModel> =>
      this.get('/api/v1/users/requests', { params, deserialize: true })

    getCakeMakerRequestById = (id: string): Promise<RequestModel> =>
      this.get(`/api/v1/cake_makers/requests/${id}`, { deserialize: true })

    getUserRequestById = (id: string): Promise<RequestModel> =>
      this.get(`/api/v1/users/requests/${id}`, { deserialize: true })

    getCustomerCountUnViewedRequests = (): Promise<CountUnViewedRequestModel> =>
      this.get(`/api/v1/users/requests/monitoring`, { deserialize: true })

    getCountUnViewedRequests = (): Promise<CountUnViewedRequestModel> =>
      this.get(`/api/v1/cake_makers/requests/monitoring`, { deserialize: true })

    createCakeMakerQuote = (values: QuoteDTO): Promise<void> =>
      this.post('/api/v1/cake_makers/quote', {
        data: { ...values }
      })

    cakeMakerEditQuote = (values: QuoteDTO): Promise<void> =>
      this.put('/api/v1/cake_makers/quote', {
        data: { ...values }
      })

    cakeMakerDeclineQuote = (values: DeclineDTO): Promise<void> =>
      this.post('/api/v1/cake_makers/decline', {
        data: values
      })

    deleteRequest = (id: number): Promise<void> =>
      this.del(`/api/v1/users/requests/${id}`)

    userDeclineQuote = (values: DeclineDTO): Promise<void> =>
      this.post('/api/v1/users/decline', {
        data: values
      })

    paymentQuote = (
      values: PaymentQuoteDTO
    ): Promise<{ stripe_payment_link: string }> =>
      this.post('/api/v1/payments/checkout_session', {
        data: values
      })
  }
}

export type QuoteMixinType = Mixin<typeof QuoteMixin>

export default QuoteMixin
