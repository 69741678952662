import type { AppProps } from 'next/app'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'

import { CacheProvider, EmotionCache } from '@emotion/react'
import CssBaseline from '@mui/material/CssBaseline'
import { Theme, ThemeProvider } from '@mui/material/styles'
import { DefaultSeo } from 'next-seo'
import { Toaster } from 'react-hot-toast'
import { Hydrate, QueryClient, QueryClientProvider } from 'react-query'

import NotificationContainer from '~/components/common/NotificationContainer'
import GTMScript from '~/containers/GTMScript'
import LocalizedProvider from '~/containers/LocalizedProvider'
import NextSeo from '~/containers/NextSeo'
import OfflineNotification from '~/containers/OfflineNotification'

// import SWBuildNotification from '~/containers/SWBuildNotification'

import useNetwork from '~/hooks/useNetwork'
import AuthGuard from '~/services/Auth/AuthGuard'
import AuthProvider from '~/services/Auth/AuthProvider'
import { IS_BROWSER } from '~/utils/constants/env.constants'
import {
  deepMerge,
  verificationValuesDehydrated
} from '~/utils/helpers/app.helpers'
import { createEmotionCache } from '~/utils/helpers/emotion.helpers'
import setupAuth from '~/utils/setupAuth'

import theme from '~/theme'
import { NextPageExtended } from '~/types/interfaces/nextjs'

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache()

interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache
  Component: NextPageExtended
  dehydratedState: any
}

const MyApp = ({
  Component,
  pageProps,
  emotionCache = clientSideEmotionCache,
  dehydratedState
}: MyAppProps) => {
  const router = useRouter()
  const [queryClient] = useState(() => {
    // Create a client
    const queryClient = new QueryClient({
      defaultOptions: {
        queries: {
          retry: 0,
          refetchInterval: false,
          refetchOnWindowFocus: false
        }
      }
    })

    // Setup Auth controller
    setupAuth(queryClient)

    return queryClient
  })

  const { isOffline, onClose } = useNetwork()

  // Use the layout defined at the page level, if available
  const getLayout = Component.getLayout || ((page) => page)
  const getMeta = Component.getMeta || ((meta = {}) => meta)

  const { auth } = Component
  const meta = getMeta()

  const hydrateState = deepMerge({
    a: dehydratedState,
    // @ts-ignore
    b: pageProps.dehydratedState,
    fn: verificationValuesDehydrated
  })

  useEffect(() => {
    if (IS_BROWSER) {
      sessionStorage.setItem('mountLink', router?.asPath)
    }
    return () => {
      if (IS_BROWSER) {
        sessionStorage.setItem('link', router?.asPath)
      }
    }
  }, [router?.asPath])

  return (
    <>
      <DefaultSeo description="CakerHQ" />

      <LocalizedProvider>
        <Head>
          <meta
            name="viewport"
            content="minimum-scale=1, initial-scale=1, width=device-width, user-scalable=0"
          />
        </Head>

        <GTMScript />

        <QueryClientProvider client={queryClient}>
          <Hydrate state={hydrateState}>
            <CacheProvider value={emotionCache}>
              <ThemeProvider<Theme> theme={theme}>
                {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
                <CssBaseline />
                <Toaster position="top-center" reverseOrder={false} />
                <AuthProvider>
                  {meta && <NextSeo {...meta} />}
                  {getLayout(
                    <AuthGuard {...auth}>
                      <Component {...pageProps} />

                      <NotificationContainer />
                    </AuthGuard>
                  )}
                </AuthProvider>

                {isOffline && (
                  <OfflineNotification
                    isOffline={isOffline}
                    onClose={onClose}
                  />
                )}

                {/* <SWBuildNotification /> */}
              </ThemeProvider>
            </CacheProvider>
          </Hydrate>
        </QueryClientProvider>
      </LocalizedProvider>
    </>
  )
}

export default MyApp
