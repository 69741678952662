import Axios from '../Axios'

import { logoutInterceptor } from './interceptors'
import ArticlesMixin, { ArticlesMixinType } from './mixins/Articles.mixin'
import ChatMixin, { ChatMixinType } from './mixins/Chat.mixin'
import ContactUsPagesMixin, {
  ContactUsPagesMixinType
} from './mixins/ContactUs.mixin'
import DashboardMixin, { DashboardMixinType } from './mixins/Dashboard.mixin'
import DirectUploadMixin, {
  DirectUploadMixinType
} from './mixins/DirectUpload.mixin'
import FavoritesMixin, { FavoritesMixinType } from './mixins/Favorites.mixin'
import OrdersMixin, { OrdersMixinType } from './mixins/Orders.mixins'
import QuoteMixin, { QuoteMixinType } from './mixins/Quote.mixin'
import ReviewMixin, { ReviewMixinType } from './mixins/Reviews.mixin'
import SettingsMixin, { SettingsMixinType } from './mixins/Settings.mixin'
import StaticPagesMixin, {
  StaticPagesMixinType
} from './mixins/StaticPage.mixin'
import UserMixin, { UserMixinType } from './mixins/User.mixin'

@UserMixin
@DirectUploadMixin
@ArticlesMixin
@StaticPagesMixin
@QuoteMixin
@FavoritesMixin
@SettingsMixin
@OrdersMixin
@ReviewMixin
@DashboardMixin
@ChatMixin
@ContactUsPagesMixin
class ApiWithMixins extends Axios {}

type ApiType = DirectUploadMixinType &
  UserMixinType &
  ArticlesMixinType &
  StaticPagesMixinType &
  QuoteMixinType &
  FavoritesMixinType &
  SettingsMixinType &
  OrdersMixinType &
  ReviewMixinType &
  DashboardMixinType &
  ChatMixinType &
  ContactUsPagesMixinType

const Api = new ApiWithMixins({
  baseURL: process.env.NEXT_PUBLIC_API,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*'
  }
}) as unknown as ApiType

Api.appendResponseErrorInterceptor(logoutInterceptor)

export default Api
