import { useRouter } from 'next/router'
import React, { useCallback } from 'react'

import { CircularProgress, Typography } from '@mui/material'

import Center from '~/components/common/Center'

import { IS_BROWSER } from '~/utils/constants/env.constants'

import { AuthContextType } from './Auth.context'
import { SelectorsAuth } from './Auth.controller'
import useAuth from './useAuth'

type GuardSchema =
  | { variant: 'redirect'; path: string }
  | { variant: 'blocking' }
  | { variant: 'loading' }
  | null

export interface AuthGuardProps {
  ssr?: boolean
  fallback?: React.ReactElement
  loader?: React.ReactElement
  guard?: (args: {
    user: UserModel
    selectors: SelectorsAuth
    state: AuthContextType['state']
  }) => GuardSchema | undefined
}

const defaultFallback = (
  <Center minHeight={400}>
    <Typography variant="h1" textAlign="center" sx={{ mb: 1.5 }}>
      Permission denied
    </Typography>
  </Center>
)

const defaultLoader = (
  <Center minHeight={400}>
    <CircularProgress size={50} />
  </Center>
)

const AuthGuard: React.FC<AuthGuardProps> = ({
  children,
  ssr,
  fallback = defaultFallback,
  loader = defaultLoader,
  guard
}) => {
  const router = useRouter()
  const { user, state, selectors } = useAuth<UserModel>()

  const getGuardBySchema = useCallback(
    (schema): GuardSchema => {
      const schemaNormalized = Object.entries(schema || {})

      if (!schemaNormalized.length) return null

      return !schemaNormalized.every(([key, value]) => {
        switch (key) {
          case 'logged':
            return selectors.isLogged() === value
          case 'confirmed':
            return selectors.isConfirmed() === value
          case 'role':
            return Array.isArray(value)
              ? value.includes(selectors.getRole())
              : selectors.getRole() === value
          default:
            return true
        }
      })
        ? { variant: 'blocking' }
        : null
    },
    [selectors]
  )

  const getGuard = useCallback(() => {
    if (typeof guard === 'function') {
      return guard({
        user,
        selectors,
        state
      })
    }

    return getGuardBySchema(guard)
  }, [user, selectors, state, guard, getGuardBySchema])

  /* Hide content when displayed on the server */
  if (ssr && !IS_BROWSER) {
    return <></>
  }

  if (guard) {
    const schema = getGuard()

    if (schema?.variant === 'loading') {
      return <>{loader}</>
    }

    if (schema?.variant === 'redirect') {
      if (IS_BROWSER && router.isReady) {
        router.push(schema.path)
      }

      return <>{loader}</>
    }

    if (schema?.variant === 'blocking') {
      return <>{fallback}</>
    }
  }

  return <>{children}</>
}

export default AuthGuard
