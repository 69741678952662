import Axios from '../../Axios'
import { AnyConstructor, Mixin } from '../types'

type AxiosType = typeof Axios

function StaticPagesMixin<TBase extends AnyConstructor>(
  Base: TBase & AxiosType
) {
  return class StaticPages extends Base {
    /**
     * Get Static pages
     */
    getAllStaticPages = (): Promise<StaticPages[]> =>
      this.get('/api/v1/static_pages')
    /**
     * Get static page by slug
     */
    getStaticPageBySlug = (slug: string): Promise<StaticPage> =>
      this.get(`/api/v1/static_pages/${slug}`)
    /**
     * Get all static pages slugs
     */
    getAllSlugStaticPages = (): Promise<{ slugs: string[] }> =>
      this.get(`/api/v1/sitemaps/static_page_slugs`)
  }
}

export type StaticPagesMixinType = Mixin<typeof StaticPagesMixin>

export default StaticPagesMixin
