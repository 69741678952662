import axios from 'axios'

import Axios from '../../Axios'
import { AnyConstructor, Mixin } from '../types'

const calculateChecksum = async (file: any): Promise<any> => {
  const crypto = (await import('crypto')).default

  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    const md5sum = crypto.createHash('md5')

    reader.readAsBinaryString(file)

    reader.onload = (e: any): void => {
      md5sum.update(e.target.result, 'binary')
      resolve(md5sum.digest('base64'))
    }

    reader.onerror = (e: any): void => {
      reject(e)
    }
  })
}

type AxiosType = typeof Axios

interface DirectUploadOptions {
  storage?: 'private' | 'public'
}

function DirectUploadMixin<TBase extends AnyConstructor>(
  Base: TBase & AxiosType
) {
  return class DirectUpload extends Base {
    /**
     * Direct upload helper
     */
    async _directUpload(
      file: any,
      options?: DirectUploadOptions
    ): Promise<{ uid: string; origin: File; attach: string }> {
      try {
        const { storage = 'public' } = options ?? {}
        const checksum = await calculateChecksum(file)

        const {
          direct_upload: { url, headers },
          // id,
          signed_id
        } = await this.post('/rails/active_storage/direct_uploads', {
          data: {
            blob: {
              filename: file.name || 'image',
              byte_size: file.size,
              content_type: file.type,
              metadata: { storage },
              checksum
            }
          }
        })

        await axios.put(url, file, {
          headers
        })

        return {
          uid: signed_id,
          origin: file,
          attach: file.name
        }
      } catch (error) {
        return Promise.reject(error)
      }
    }
    /**
     * Direct upload of one file
     */
    directUploadOne(
      this: any,
      file: any,
      uploadLink: string,
      options?: DirectUploadOptions
    ): Promise<{ attach: string; origin: File; uid: string }> {
      return this._directUpload(file, options)
    }
    /**
     * Direct upload of all files
     */
    directUploadAll(
      files: any
    ): Promise<Array<{ signed_id: string; origin: Record<string, any> }>> {
      return Promise.all(files.map((file: any) => this._directUpload(file)))
    }
  }
}

export type DirectUploadMixinType = Mixin<typeof DirectUploadMixin>

export default DirectUploadMixin
