import Axios from '../../Axios'
import { AnyConstructor, Mixin } from '../types'

type AxiosType = typeof Axios

function ChatMixin<TBase extends AnyConstructor>(Base: TBase & AxiosType) {
  return class Chat extends Base {
    /**
     * Get all chat tabs
     */
    getAllChatTabs = (): Promise<Tab[]> =>
      this.get(`/api/v1/chats`, {
        deserialize: true
      })

    /**
     * Create new chat by id
     */
    createChat = (values: ChatDTO): Promise<Tab> =>
      this.post(`/api/v1/chats`, {
        data: {
          chat: values
        },
        deserialize: true
      })

    /**
     * Get chat by id
     */
    getAllChatMessages = (chatId: string): Promise<MessageModel[]> =>
      this.get(`/api/v1/chats/${chatId}/messages`, {
        deserialize: true
      })

    /**
     * Create message for chat
     */
    createChatMessage = ({
      chatId,
      message
    }: {
      chatId: string
      message: MessageDTO
    }): Promise<MessageModel> =>
      this.post(`/api/v1/chats/${chatId}/messages`, {
        data: { message },
        deserialize: true
      })

    /**
     * Mark as read message for chat
     */
    markAsReadMessages = (chatId: string): Promise<void> =>
      this.get(`/api/v1/chats/${chatId}/mark_as_reads`)

    /**
     * Get all notification message
     */
    getAllNotificationsMessage = (): Promise<{
      total_unread_messages: number
    }> => this.get(`/api/v1/chats/notifications`)
  }
}

export type ChatMixinType = Mixin<typeof ChatMixin>

export default ChatMixin
