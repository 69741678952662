import { createContext } from 'react'

import Auth, { ActionsAuth, RolesAuth, SelectorsAuth } from './Auth.controller'

export type AuthContextType<UserT = any> = {
  user: UserT
  state: {
    error: any
    isFetched: boolean
    isLoading: boolean
    isIdle: boolean
    isAuthorized: boolean
    isLogged: boolean
    isConfirmed: boolean
  }
  methods: {
    refetch: () => void
    getUser: typeof Auth.controller.getUser
    clearUser: typeof Auth.controller.clearUser
    setUser: typeof Auth.controller.setUser
  }
  actions: ActionsAuth
  selectors: SelectorsAuth
  roles: RolesAuth
}

const AuthContext = createContext<AuthContextType>({} as AuthContextType)

export default AuthContext
