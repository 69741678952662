import Axios from '../../Axios'
import { AnyConstructor, Mixin } from '../types'

type AxiosType = typeof Axios

function ContactUsPagesMixin<TBase extends AnyConstructor>(
  Base: TBase & AxiosType
) {
  return class ContactUsPages extends Base {
    sendMessageForAdmin = (values: ContactUSDTO): Promise<string> =>
      this.post('/api/v1/contact_us', { data: { ...values } })
  }
}

export type ContactUsPagesMixinType = Mixin<typeof ContactUsPagesMixin>

export default ContactUsPagesMixin
