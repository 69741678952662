import { IS_BROWSER } from '~/utils/constants/env.constants'

import BaseStorage from './BaseStorage'

type ItemName =
  | 'accessToken'
  | 'confirmationEmail'
  | 'showWelcomePopup'
  | 'rating'
  | 'redirectUrl'

class LocalStorage extends BaseStorage<ItemName> {
  constructor() {
    super()

    if (IS_BROWSER) {
      this.storage = window.localStorage
    }
  }
}

const localStorage = new LocalStorage()

export default localStorage
