import { useRouter } from 'next/router'
import Script from 'next/script'
import { FC, useEffect } from 'react'

import * as gtag from '~/utils/helpers/gtag.helpers'

const GTMScript: FC = () => {
  const router = useRouter()

  useEffect(() => {
    router.events.on('routeChangeComplete', gtag.pageview)
    return () => {
      router.events.off('routeChangeComplete', gtag.pageview)
    }
  }, [router.events])

  return (
    <Script
      id="next-gtm"
      strategy="afterInteractive"
      dangerouslySetInnerHTML={{
        __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${gtag.GTM_ID}');
          `
      }}
    />
  )
}

export default GTMScript
