import dynamic from 'next/dynamic'

import localStorage from '~/services/Storage/LocalStorage'

const WelcomeInfo = dynamic(() => import('~/components/common/WelcomeInfo'), {
  ssr: false
})

const WelcomeInfoContainer = ({ isOpen, onClose }) => {
  const isWelcomePopup = localStorage.getItem('showWelcomePopup')

  if (!isWelcomePopup) return null

  return <WelcomeInfo isOpen={isOpen} onClose={onClose} />
}

export default WelcomeInfoContainer
